<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-card class="elevation-x py-3">
    <v-card-title class="pt-0 font-alt">Dossiers en cours</v-card-title>
    <v-card-subtitle class="font-weight-light text-caption font-alt">Liste de vos dossiers en cours</v-card-subtitle>
    <admissions-list />
  </v-card>
</template>

<script>
  import AdmissionsList from '@/modules/professionals/components/list/AdmissionsList'

  export default {
    name: 'AdmissionsPending',
    components: {AdmissionsList}
  }
</script>

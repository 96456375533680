class LocalStorage {
  get(key) {
    if(!window.localStorage.getItem(key)) {
      return false
    }
    return JSON.parse(window.localStorage.getItem(key))
  }

  set(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value))
  }
}

export default LocalStorage

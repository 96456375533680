<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <div v-if="!loading">
    <admissions-list-header :filters.sync="filters" :items-per-page.sync="itemsPerPage"
                            @search="search" @reset="reset"
    />
    <v-divider />
    <v-data-table
      :headers="headers"
      :items="stays"
      hide-default-footer
      :page.sync="page"
      :options.sync="options"
      :items-per-page="itemsPerPage"
      class="c-pointer pt-5 admissions-list"
      :server-items-length="totalItems"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      multi-sort
      :loading="isLoading"
      loading-text="Chargement des données en cours.. Veuillez patienter"
      @click:row="admissionCard"
    >
      <template v-slot:item.status="{ item }">
        <v-chip x-small :color="getColorStatus(item.status)" dark class="font-alt">
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:item.relaunch="{ item }">
        <v-chip x-small :color="getColorRelaunch(item.relaunch)" dark>
          {{ item.relaunch }}
        </v-chip>
      </template>
      <template v-slot:item.patientIdentity.birthDate="{ item }">
        <span class="text-caption o-80">{{ $dayjs(item.patientIdentity.birthDate).format('DD/MM/YYYY') }}</span>
      </template>
      <template v-slot:item.number="{ item }">
        <v-chip x-small label>
          <span class="text-caption font-weight-medium">
            {{ item.number ? item.number : 'N/A' }}
          </span>
        </v-chip>
      </template>
      <template v-slot:item.startDate="{ item }">
        <span class="text-caption">{{ $dayjs(item.startDate).format('DD/MM/YYYY') }}</span>
      </template>
      <template v-slot:item.service="{ item }">
        <span class="text-caption o-80">{{ item.service && item.service.label ? item.service.label : '' }}</span>
      </template>
      <template v-slot:item.doctor="{ item }">
        <span class="o-80">{{ item.doctor ? 'Dr. ' + item.doctor.firstname + ' ' + item.doctor.lastname : '' }}</span>
      </template>
      <template v-slot:item.completionRate="{ item }">
        <v-chip v-if="archived" dark x-small dense class="font-weight-medium"
                :color="completionColorArchived(item.status)" :value="completionTextArchived(item.status)"
        >
          {{ completionTextArchived(item.status) }}
        </v-chip>
        <v-chip v-else-if="cancelled" dark x-small dense class="font-weight-medium" color="orange">
          Dossier annulé
        </v-chip>
        <v-chip v-else x-small dense class="font-weight-medium" :color="completionToColor(item.completionRate)">
          {{ item.completionRate }}%
        </v-chip>
      </template>
    </v-data-table>
    <div class="py-3 d-flex justify-center">
      <v-pagination
        v-model="page"
        :length="pageCount"
        total-visible="5"
      />
    </div>
  </div>
</template>

<script>
  import { clean, deepCopy } from '@/utils/object'
  import AdmissionsListHeader from '@/modules/professionals/components/list/AdmissionsListHeader'
  import { list } from '@/modules/stays/api'
  import dataDisplayers from '@/mixins/dataDisplayers'
  import LocalStorage from '@/utils/LocalStorage'

  const INITIAL_FILTERS = {
    number: null,
    'patient.lastName': null,
    'patient.birthName': null,
    'patient.firstName': null,
    'patientIdentity.birthDate': null,
    startDate: {
      before: null,
      after: null
    }
  }

  const localDB = new LocalStorage()
  const initalFilters = deepCopy(INITIAL_FILTERS)
  const initalItemsPerPage =  10

  export default {
    name: 'AdmissionsList',
    components: {AdmissionsListHeader},
    mixins: [dataDisplayers],
    props: {
      archived: Boolean,
      cancelled: Boolean
    },
    data() {
      return {
        loading: true,
        page: 1,
        stays: [],
        pageCount: 0,
        options: {},
        totalItems: 0,
        itemsPerPage: null,
        isLoading: false,
        sortBy: ['startDate'],
        sortDesc: [this.archived],
        filters: null,
        menuType: [
          {
            title: 'Nouvelle admission',
            subtitle: 'Créer une nouvelle admission',
            icon: 'folder-plus'
          },
          {
            title: 'Dossiers en cours',
            subtitle: 'Voir les dossiers en cours',
            icon: 'folder-open'
          },
          {
            title: 'Dossiers archivés',
            subtitle: 'Voir les dossiers archivés',
            icon: 'archive'
          },
          {
            title: 'Dossiers annulés',
            subtitle: 'Voir les dossiers annulés',
            icon: 'cancel'
          }
        ],
        items: [
          'En cours',
          'Non lu',
          'Dossier complet'
        ],
        headers: [
          {
            text: 'Nom usuel',
            value: 'patientIdentity.lastName'
          },
          {
            text: 'Nom de naissance',
            value: 'patientIdentity.birthName'
          },
          {
            text: 'Prénom',
            value: 'patientIdentity.firstName'
          },
          {
            text: 'Date de naissance',
            value: 'patientIdentity.birthDate',
            align: 'center'
          },
          {
            text: 'Numéro de séjour',
            value: 'number',
            align: 'center'
          },
          {
            text: 'Date de début de séjour',
            value: 'startDate',
            align: 'center'
          },
          {
            text: 'Service',
            value: 'service'
          },
          {
            text: 'Médecin',
            value: 'doctor'
          },
          {
            text: 'Complétion',
            value: 'completionRate',
            align: 'center'
          }
        ]
      }
    },
    computed: {
      filtersStoreKey() {
        if(this.archived) {
          return 'archived_admission_filters'
        } else if (this.cancelled) {
          return 'cancelled_admission_filters'
        } else {
          return 'pending_admission_filters'
        }
      },
      itemsPerPageStoreKey() {
        if(this.archived) {
          return 'archived_admission_items'
        } else if (this.cancelled) {
          return 'cancelled_admission_items'
        } else {
          return 'pending_admission_items'
        }
      },
      params() {
        let params = {
          ...this.filters,
          page: this.page,
          itemsPerPage: this.itemsPerPage,
          'service.establishment': this.$auth.user.identity.professionalIdentityEstablishments[0].establishment['@id']
        }

        if (this.sortBy.length > 0) {
          params.order = {}
          this.sortBy.forEach((value, index) => {
            let key
            // transform column name to api parameter for sorting
            switch (value) {
              case 'service':
                key = 'service.label'
                break
              case 'doctor':
                key = 'doctor.lastName'
                break
              default:
                key = value
            }
            params.order[key] = (this.sortDesc[index]) ? 'desc' : 'asc'
          })
        }
        return params
      }
    },
    watch: {
      options: {
        handler() {
          this.$nextTick(this.loadStays)
        },
        deep: true
      }
    },
    async created() {
      if(!localDB.get(this.itemsPerPageStoreKey)) {
        localDB.set(this.itemsPerPageStoreKey, initalItemsPerPage)
      }

      if(!localDB.get(this.filtersStoreKey)) {
        localDB.set(this.filtersStoreKey, initalFilters)
      }

      this.itemsPerPage = localDB.get(this.itemsPerPageStoreKey) ?? initalItemsPerPage
      this.filters = localDB.get(this.filtersStoreKey) ?? initalFilters

      this.loading = false
    },
    methods: {
      admissionCard(value) {
        this.$router.push({name: 'admission-card', params: {id: value.id}})
      },
      loadStays() {
        this.isLoading = true
        let params = clean(deepCopy(this.params))
        params.archived = this.archived
        params.cancelled = this.cancelled

        list({params}).then((response) => {
          this.stays = response.data['hydra:member']
          this.totalItems = response.data['hydra:totalItems']
          this.pageCount = Math.ceil(this.totalItems / this.itemsPerPage)
          this.isLoading = false
        })
      },
      search() {
        this.page = 1
        localDB.set(this.itemsPerPageStoreKey, this.itemsPerPage)
        localDB.set(this.filtersStoreKey, this.filters)
        this.loadStays()
      },
      reset() {
        this.page = 1
        this.itemsPerPage = initalItemsPerPage
        this.filters = initalFilters
        localDB.set(this.itemsPerPageStoreKey, initalItemsPerPage)
        localDB.set(this.filtersStoreKey, initalFilters)
        this.loadStays()
      },
      completionColorArchived(status) {
        let color = ''
        if (status === 'in_progress') {
          color = 'grey'
        } else {
          color = 'success'
        }
        return color
      },
      completionTextArchived(status) {
        let text = ''
        if (status === 'in_progress') {
          text = 'Dossier clôturé'
        } else {
          text = 'Dossier complet'
        }
        return text
      }
    }
  }
</script>
<style lang="scss" scoped>
  .admissions-list {
    td {
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
</style>

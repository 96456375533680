var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',[_c('admissions-list-header',{attrs:{"filters":_vm.filters,"items-per-page":_vm.itemsPerPage},on:{"update:filters":function($event){_vm.filters=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"search":_vm.search,"reset":_vm.reset}}),_c('v-divider'),_c('v-data-table',{staticClass:"c-pointer pt-5 admissions-list",attrs:{"headers":_vm.headers,"items":_vm.stays,"hide-default-footer":"","page":_vm.page,"options":_vm.options,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalItems,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"multi-sort":"","loading":_vm.isLoading,"loading-text":"Chargement des données en cours.. Veuillez patienter"},on:{"update:page":function($event){_vm.page=$event},"update:options":function($event){_vm.options=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.admissionCard},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-alt",attrs:{"x-small":"","color":_vm.getColorStatus(item.status),"dark":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.relaunch",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"x-small":"","color":_vm.getColorRelaunch(item.relaunch),"dark":""}},[_vm._v(" "+_vm._s(item.relaunch)+" ")])]}},{key:"item.patientIdentity.birthDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-caption o-80"},[_vm._v(_vm._s(_vm.$dayjs(item.patientIdentity.birthDate).format('DD/MM/YYYY')))])]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"x-small":"","label":""}},[_c('span',{staticClass:"text-caption font-weight-medium"},[_vm._v(" "+_vm._s(item.number ? item.number : 'N/A')+" ")])])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$dayjs(item.startDate).format('DD/MM/YYYY')))])]}},{key:"item.service",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-caption o-80"},[_vm._v(_vm._s(item.service && item.service.label ? item.service.label : ''))])]}},{key:"item.doctor",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"o-80"},[_vm._v(_vm._s(item.doctor ? 'Dr. ' + item.doctor.firstname + ' ' + item.doctor.lastname : ''))])]}},{key:"item.completionRate",fn:function(ref){
var item = ref.item;
return [(_vm.archived)?_c('v-chip',{staticClass:"font-weight-medium",attrs:{"dark":"","x-small":"","dense":"","color":_vm.completionColorArchived(item.status),"value":_vm.completionTextArchived(item.status)}},[_vm._v(" "+_vm._s(_vm.completionTextArchived(item.status))+" ")]):(_vm.cancelled)?_c('v-chip',{staticClass:"font-weight-medium",attrs:{"dark":"","x-small":"","dense":"","color":"orange"}},[_vm._v(" Dossier annulé ")]):_c('v-chip',{staticClass:"font-weight-medium",attrs:{"x-small":"","dense":"","color":_vm.completionToColor(item.completionRate)}},[_vm._v(" "+_vm._s(item.completionRate)+"% ")])]}}],null,false,2134405296)}),_c('div',{staticClass:"py-3 d-flex justify-center"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":"5"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
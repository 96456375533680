<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-form class="px-3 pb-7" @submit.prevent="submitSearch">
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field v-model="localFilters['patient.lastName']" :maxlength="150" outlined dense hide-details="auto" label="Nom usuel" clearable />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="localFilters['patient.birthName']" :maxlength="150" outlined dense hide-details="auto"
                      label="Nom de naissance"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="localFilters['patient.firstName']" :maxlength="150" outlined dense hide-details="auto" label="Prénom" clearable />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="localFilters.number" :maxlength="100" outlined dense hide-details="auto" label="Numéro de séjour" clearable />
      </v-col>
      <v-col cols="12" md="3">
        <date-picker v-model="startDate" :max-length="50" label="Date de début du séjour" />
      </v-col>
      <v-col cols="12" md="3">
        <date-picker v-model="localFilters['patientIdentity.birthDate']" :max-length="50" label="Date de naissance" birth-date />
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field outlined dense hide-details="auto"
                      :value="localItemsPerPage"
                      label="Nombre de résultats par page"
                      type="number"
                      min="1"
                      max="15"
                      @input="localItemsPerPage = parseInt($event, 10)"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-btn small elevation="0" color="primary" type="submit">
          <font-awesome-icon :icon="['fad', 'search']" class="mr-2" />
          Rechercher
        </v-btn>
        <v-btn small tile elevation="0" class="ml-2" @click="reset">
          Réinitialiser
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import DatePicker from '@/modules/core/components/DatePicker'
  export default {
    name: 'AdmissionsListHeader',
    components: {DatePicker},
    props: {
      filters:Object,
      itemsPerPage: Number
    },
    data() {
      return {
        startDate: null
      }
    },
    computed: {
      localFilters: {
        get() {
          return this.filters
        },
        set(value) {
          this.$emit('update:filters', value)
        }
      },
      localItemsPerPage: {
        get() {
          return this.itemsPerPage
        },
        set(value) {
          this.$emit('update:itemsPerPage', value)
        }
      }
    },
    watch:{
      startDate(value) {
        if(value) {
          this.localFilters.startDate.before = this.$dayjs(value).add(1, 'day').format('YYYY-MM-DD')
          this.localFilters.startDate.after = value
        } else {
          this.resetDate()
        }
      }
    },
    created() {
      if(this.localFilters.startDate.after !== null) {
        this.startDate = this.localFilters.startDate.after
      }
    },
    methods: {
      resetDate() {
        this.localFilters.startDate.before = null
        this.localFilters.startDate.after = null
        this.startDate = null
      },
      reset() {
        this.resetDate()
        this.$emit('reset')
      },
      submitSearch() {
        this.$emit('search')
      }
    }
  }
</script>

<style scoped>

</style>
